import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import LoadingSpinner from '~components/LoadingSpinner';
import BackgroundImage from '~images/other/flow-background.svg';

const ShavuotEventRedirectPage = () => {
  useEffect(() => {
    window.location.href = 'https://mailchi.mp/alephbeta.org/shavuot-event';
  }, []);

  return (
    <Container center size={100} background={`url(${BackgroundImage})`} maxWidth="md">
      <Typography variant="h3" align="center">
        Please wait while we are redirecting you to the Shavuot Event Page.
      </Typography>
      <LoadingSpinner />
    </Container>
  );
};

export default ShavuotEventRedirectPage;
